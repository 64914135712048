//引用Vuex
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

//实例store对象
const store = new Vuex.Store({
    state: {
        // 验证手机号域名切换
        // // 测试服
        phoneUrl: 'https://test1.jdcloud.vip/api/v2/common/mobilecode',
        codeTencent: '2034218683',
        URL: 'https://test2.jdcloud.vip/',
        // 正式服
        // phoneUrl: 'https://www.healthplatform.xyz/api/v2/common/mobilecode',
        // codeTencent: '2006574121',
        // URL: 'https://trade.healthplatform.xyz/'
    },
    mutations: {
        /*......*/
    }
})

//导出store对象
export default store